.home-container {
    text-align: center;
    margin: 20px;
  }
  
  h1 {
    color: rgb(93, 6, 160);
  }
  
  p {
    font-size: 18px;
    color:rgb(158, 78, 219)
  }
  