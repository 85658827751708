.left-arrow,
.right-arrow {
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #ffffff;
}

/* Adjust positioning as needed */
.left-arrow {
  position: absolute;
  left: 10px;
}

.right-arrow {
  position: absolute;
  right: 10px;
}

.carousel-item {
  height: 500px; /* Adjust this value based on your needs */
}

.carousel-item img {
  max-height: 100%;
  object-fit: cover; /* This ensures that the image covers the area without distortion */
}
