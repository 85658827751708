.contact-form {
    max-width: 400px;
    margin: auto;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 8px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 1px;
    margin-bottom: 8px;
    box-sizing: border-box;
  }
  
  .contact-form button {
    background-color: #844caf;
    color: rgb(229, 227, 227);
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  .contact-form button:hover {
    background-color: #7124de;
  }
  