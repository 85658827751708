.navigation {
  z-index: 1050; /* Make sure this number is higher than any other element on the page */
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
}

.menu-toggle {
  display: block;
  background: none;
  border: none;
  font-size: 50px;
  cursor: pointer;
}

.menu {
  z-index: 1450; /* Make sure this number is higher than any other element on the page */
  position: fixed;
  top: 0;
  right: -200px; /* Initially off-screen */
  width: 200px;
  height: 100%;
  background-color: rgb(86, 5, 235);
  transition: right 0.3s ease;
}

.navigation.open .menu {
  right: 0; /* Move on-screen when the open class is present */
  transition: right 0.3s ease;
}

.menu.open {
  right: 0;
  transition: right 0.3s ease;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  padding: 10px;
}

.menu a {
  text-decoration: none;
  color: white;
  font-size: 25px;
}

.menu-toggle:hover,
.menu a:hover {
  color: #570992;
}
