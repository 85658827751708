.leaderboard-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust this value based on your design preference */
  }
  
  .leaderboard-table {
    width: 100%;
    max-width: 800px; /* Set a maximum width if desired */
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .leaderboard-table th, .leaderboard-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #000000;
  }
  
  .leaderboard-table th {
    background-color: #000000;
    color: white; /* Set text color for th */
  }
  
  /* Alternating row colors */
  .leaderboard-table tr:nth-child(odd) {
    background-color: rgb(129, 18, 203);
    color: white;
  }
  
  .leaderboard-table tr:nth-child(even) {
    background-color: grey;
    color: white;
  }
  