.signup-form {
    max-width: 300px;
    margin: auto;
  }
  
  .signup-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .signup-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
  }
  
  .signup-form button {
    background-color: #6d1dd5;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  .signup-form button:hover {
    background-color: #9c5cea;
  }
  
  p {
    text-align: center;
    margin-top: 20px;
  }
  
  p a {
    color: #b48de3;
  }
  